<template>
  <div class="navTab flex_row_between">
    <div class="logo">
    </div>

    <div class="title flex1 flex_row_center">
      <div class="titleSingle" @click="turnPage(1)">首页</div>
      <div class="titleSingle" @mouseleave="showHistory = false" @mouseenter="showHistory = true" @click="turnPage(2)">
        现状分析 <span style="position: relative;top: 3px;"><el-icon v-if="showHistory">
            <ArrowUpBold />
          </el-icon> <el-icon v-else>
            <ArrowDownBold />
          </el-icon></span>
        <div @click="turnPage(2)" v-if="showHistory" class="hisdown">
          历史记录
        </div>
      </div>
      <div class="titleSingle" @click="turnPage(3)">关键词挖掘</div>
      <div class="titleSingle" @mouseleave="showAIMain = false" @mouseenter="showAIMain = true">
        AIGC内容生成 <span style="position: relative;top: 3px;"><el-icon v-if="showAIMain">
            <ArrowUpBold />
          </el-icon> <el-icon v-else>
            <ArrowDownBold />
          </el-icon></span>
        <div v-if="showAIMain" class="nrscdown">
          <div style="margin-top: 18px;" @click="turnPage(4)" class="navSecond" @mouseenter="lineHeightChoose = 1"
            @mouseleave="lineHeightChoose = 0" :class="lineHeightChoose == 1 ? 'lineHeight' : ''">词生文</div>
          <div class="navSecond" @click="turnPage(5)" @mouseenter="lineHeightChoose = 2"
            @mouseleave="lineHeightChoose = 0" :class="lineHeightChoose == 2 ? 'lineHeight' : ''">文生文</div>
        </div>
      </div>
      <div class="titleSingle" @click="turnPage(6)">智能分发</div>
      <div class="titleSingle" @click.stop="turnPage(8)" @mouseleave="showAct = false" @mouseenter="showAct = true">
        用户行为模拟
        <span style="position: relative;top: 3px;"><el-icon v-if="showAct">
            <ArrowUpBold />
          </el-icon> <el-icon v-else>
            <ArrowDownBold />
          </el-icon></span>
        <div v-if="showAct" class="nrscdown">
          <div style="margin-top: 18px;" @click.stop="turnPage(7)" class="navSecond" @mouseenter="lineHeightChoose = 1"
            @mouseleave="lineHeightChoose = 0" :class="lineHeightChoose == 1 ? 'lineHeight' : ''">数据模拟</div>
          <div class="navSecond" @click.stop="turnPage(8)" @mouseenter="lineHeightChoose = 2"
            @mouseleave="lineHeightChoose = 0" :class="lineHeightChoose == 2 ? 'lineHeight' : ''">行为模拟</div>
        </div>
      </div>
      <div class="titleSingle" @mouseleave="showRole = false" @mouseenter="showRole = true">权限管理
        <span style="position: relative;top: 3px;"><el-icon v-if="showRole">
            <ArrowUpBold />
          </el-icon> <el-icon v-else>
            <ArrowDownBold />
          </el-icon></span>
        <div v-if="showRole" class="nrscdown">
          <div style="margin-top: 18px;" @click.stop="turnPage(9)" class="navSecond" @mouseenter="lineHeightChoose = 1"
            @mouseleave="lineHeightChoose = 0" :class="lineHeightChoose == 1 ? 'lineHeight' : ''">角色组</div>
          <div class="navSecond" @click.stop="turnPage(10)" @mouseenter="lineHeightChoose = 2"
            @mouseleave="lineHeightChoose = 0" :class="lineHeightChoose == 2 ? 'lineHeight' : ''">管理员管理</div>
        </div>
      </div>
    </div>

    <el-dropdown v-if="isLogin">
      <span class="el-dropdown-link" style="cursor: pointer;">
        <div class="admin flex_row_left">
          <el-badge is-dot class="item">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
          </el-badge>
          <div class="adminName overline1">{{ username?username:'' }}</div>
        </div>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>


  </div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus'
// const userRoute = useRoute()
const userRouter = useRouter()
const showHistory = ref(false)
const showAIMain = ref(false)
const showAct = ref(false)
const showRole = ref(false)
let admin = reactive({
  name: 'admin',
  head: ''
})
const isLogin = ref(true)
const username = ref('admin')
watch(
  () => userRouter.currentRoute.value,
  (newValue) => {
    console.log(newValue)
    if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
      isLogin.value = true
    } else {
      isLogin.value = false
    }
    if (localStorage.getItem('username') && localStorage.getItem('username') != '') {
      username.value = localStorage.getItem('username')
    } else {
      username.value = 'admin'
    }
  },
  { immediate: true }
)
function logout() {
  ElMessageBox.alert('确认退出登录吗?', '提示', {
    confirmButtonText: '确认退出',
    callback: (action) => {
      if (action == 'confirm') {
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('currentProgram')
        isLogin.value = false
        userRouter.push({
          path: '/'
        })
      }
    },
  })
}
let lineHeightChoose = ref(0)
const turnPage = (v) => {
  console.log(v)
  switch (v) {
    case 1:
      userRouter.push({
        path: '/second'
      })
      break;
    case 2:
      userRouter.push({
        path: '/analysis/history'
      })
      break;
    case 3:
      userRouter.push({
        path: '/search'
      })
      break;
    case 4:
      userRouter.push({
        path: '/ai/aiWord'
      })
      break;
    case 5:
      userRouter.push({
        path: '/ai/aiArticle'
      })
      break;
    case 6:
      userRouter.push({
        path: '/distribute'
      })
      break;
    case 7:
      userRouter.push({
        path: '/behavior/data'
      })
      break;
    case 8:
      userRouter.push({
        path: '/behavior/set'
      })
      break;
    case 9:
      userRouter.push({
        path: '/role/group'
      })
      break;
    case 10:
      userRouter.push({
        path: '/role/admin'
      })
      break;
    default:
      break;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .el-badge__content.is-dot {
  width: 8px;
  height: 8px;
  background: #00FF58;
  border: none;
  margin-top: 2px;
}

.hisdown {
  background: url('../assets/xzfxbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: black;
  height: 65px;
  width: 102px;
  text-align: center;
  line-height: 65px;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 99999;
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-size: 15px;
  color: #333333;
  text-align: center;
}

.nrscdown {
  width: 102px;
  height: 100px;
  background: url('../assets/nrscbg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: -20px;
  z-index: 99999;
}

.item {
  margin-right: 8px;
}

.adminName {
  max-width: 80px;
  font-family: PingFangSC, PingFang SC;
  font-weight: normal;
  font-size: 17px;
  color: #FFFFFF;
  height: 24px;
  line-height: 24px;
  font-style: normal;
  white-space: normal;
}

.lineHeight {
  color: rgba(106, 90, 233, 1);
  background-color: rgba(106, 90, 233, 0.18);
}

.navSecond {
  /* background-color: rgba(106, 90, 233, 0.18); */
  width: 100%;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 15px;
  text-transform: uppercase;
  font-family: PingFangSC-;
  text-align: center;
  white-space: nowrap;
  line-height: 40px;
  position: relative;
  top: 10px;
}

.title {
  display: flex;
  justify-content: center;
  line-height: 36px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #FFFFFF;
  text-align: right;
  font-style: normal;
  overflow-y: visible;
}

.titleSingle {
  height: 36px;
  line-height: 36px;
  padding: 0 30px;
  min-width: 102px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}

.admin {
  /* position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  height: 38px;
  line-height: 38px; */
}


.navTab {
  width: 100vw;
  height: 68px;
  background: #074A91;
  position: absolute;
  top: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 0px 24px;
}

.logo {
  width: 160px;
  min-width: 160px !important;
  height: 35px;
  background-image: url(@/assets/logo.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
