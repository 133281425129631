<template>
  <div>
    <navTab></navTab>
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// import { defineAsyncComponent } from 'vue'
import navTab from '@/components/navTab.vue';
// const navTab = defineAsyncComponent(() =>
//   import('@/components/navTab.vue')
// );


export default defineComponent({
  components: { navTab },
  setup(){
    const debounce = (callback, delay) => {
      let tid;
      return function (...args) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };

    const _ = window.ResizeObserver;
    (window).ResizeObserver = class ResizeObserver extends _ {
      constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
      }
    };
  }

})
</script>

<style>
@import './style/element.css';
</style>
