<template>
    <div class="more">
        <div class="head">
            <div class="breadcrumbs">
                <span>
                    <div @click="backHome"
                        style="display: inline-block;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;color: rgba(255,255,255,0.4);line-height: 22px;text-align: left;font-style: normal;cursor: pointer;">
                        现状分析</div>
                </span> &emsp13;&emsp13;<span>
                    <div
                        style="display: inline-block;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;color: #FFFFFF;line-height: 22px;text-align: left;font-style: normal;">
                        > {{ subtitle?subtitle:'' }}</div>
                </span>
            </div>
            <div class="proname">项目名称</div>
        </div>
        <div style="display: flex;justify-content: center;margin-top: 30px;">
            <div style="width: 800px;margin: auto;">
                <el-input v-model="searchKey" style="width: 703px;margin-right: 20px;" placeholder="请输入搜索关键词"
                    class="input-with-select">
                    <template #append>
                        <el-button @click="searchSomething"
                            style="background-color:#0777FC ;color: white;">搜索</el-button>
                    </template>
                </el-input>
                <!-- <el-button color="#6A5AE9">
                    重置
                </el-button> -->
            </div>

        </div>
        <div>
            <div style="width: 97.5vw;background-color: #071229;margin: auto;margin-top:20px;padding-bottom: 24px;">
                <el-table :data="tableData" style="background-color: #071229;" :cell-style="cellStyle"
                    :header-cell-style="headerStyle">
                    <el-table-column prop="id" label="ID" min-width="180" />
                    <el-table-column prop="keyword" label="关键词">
                        <template #default="scope">
                            <div class="flex_row_center">{{ scope.row.gl_keyword }}
                                <!-- <img style="width: 16px;height: 16px;margin-left: 2px;" src="@/assets/tuo.png" alt=""> -->
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="keyword" label="位置">

                    </el-table-column> -->

                    <el-table-column label="调性">
                        <template #default="scope">
                            <div
                                style="height: 22px;font-weight: 400;font-size: 14px;color: #00DFFF;line-height: 22px;text-align: center;">
                                <div style="color: #00DFFF;" v-if="scope.row.attr_type == 3">正面</div>
                                <div style="color: #FF355E;" v-if="scope.row.attr_type == 1">负面</div>
                                <div style="color: #00DFFF;" v-if="scope.row.attr_type == 2">中性</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="平台">
                        <template #default="scope">
                            <div class="flex_row_center">{{ scope.row.plat ? getName(scope.row.plat) : '' }}
                            </div>
                        </template>
                    </el-table-column>

                </el-table>
                <el-pagination background layout="prev, pager, next" :total="page_config.count"
                    :page-size="params.pagesize" :current-page="params.page" :default-current-page="1"
                    @current-change="currentChange" style="margin-top: 122px;" class="flex_row_center" />
            </div>
        </div>
        <div style="width: 688px; padding-left:15px;margin: auto; background-color: #fff;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;"
            v-if="beforeSearch">
            <div class="searchMain" v-for="exam in searchArr" :key="exam.id">
                <el-icon class="no-inherit" style="margin-right: 18px;position: relative;top: 3px;">
                    <Search />
                </el-icon> {{ exam.text }}
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { https } from '@/request.js';
const Route = useRoute()
const userRouter = useRouter()
const searchArr = ref([])
const beforeSearch = ref(true)

let breadWord = ref('')
console.log(searchArr)
let timer = null;
const tableData = ref([

])
const palts = ref([])
function getPlat() {
    https.post('/index/index/getPlat', {}).then(res => {
        if (res.code == 0) {
            palts.value = res.data
        }
    })
}
getPlat()
function getName(e) {
    let i = palts.value.findIndex(item => item.plat == e)
    return palts.value[i].title
}
const params = ref({
    page: 1,
    pagesize: 10,
    keyword: Route.query.keyword,
    project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
    plat: '',
    type: 3
})
const page_config = ref({
    count: 0,
    current: "1",
    last_page: 0,
    next: 0,
    pre: ""
})
const subtitle = ref('')
if (Route.query.title) {
    subtitle.value = Route.query.title
    params.value.type = Route.query.type
    params.value.plat = Route.query.plat
} else {
    subtitle.value = '大家还在搜'
}
function currentChange(val) {
    params.value.page = val;
    getList()
}
const searchKey = ref('')
const searchSomething = () => {
    params.value.keyword = searchKey.value
    getList()
}
function getList() {
    https.post('/index/Keyword/keywordLink', params.value).then(res => {
        if (res.code == 0) {
            tableData.value = res.data.list
            page_config.value = res.data.page_config
        }
    })
}
getList()
const backHome = () => {
    userRouter.go(-1)
}
const cellStyle = () => {
    return {
        color: "#fff",
        textAlign: "center",
        background: "#071229",
        fontWeight: 700,
        height: '46px',
        lineHeight: '46px',
        padding: '0px',
        border: 'none',
        borderBottom: '1px solid rgba(15,178,245,0.11)',
    };

}
const headerStyle = () => {
    return {
        color: "#fff",
        textAlign: "center",
        background: "rgba(106, 90, 233, 0.12)",
        fontWeight: 700,
        height: '45px',
        lineHeight: '45px',
        border: 'none'
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.head {
    width: 97.5vw;
    margin: auto;
    text-align: left;
    margin-top: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.line0 {
    width: 28px;
    height: 3px;
    background: #FF1515;
    border-radius: 3px;
    margin: 0 10px 0 4px
}

.line1 {
    background: #EAB31A;
}

.line2 {
    background: #1A60DA;
}

.line3 {
    background: #1ADA33;
    margin: 0 0px 0 4px
}

.status {
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #00DFFF;
    line-height: 22px;
    text-align: center;
    font-style: normal;
}

.red {
    color: #FF355E;
}

.breadcrumbs {
    color: white;
}

.searchMain {
    height: 40px;
    line-height: 40px
}

.more {
    width: 100vw;
    height: 100vh;
    background-image: url(@/assets/bgi.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.proname {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
}
</style>