<template>
    <div class="overview">
        <div class="head">
            <div class="breadcrumbs">
                <span>
                    <div @click="backHome"
                        style="display: inline-block;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;color: rgba(255,255,255,0.4);line-height: 22px;text-align: left;font-style: normal;">
                        首页</div>
                </span> &emsp13;<div style="display: inline-block;" v-if="breadWord.split('').length > 0"></div>
                &emsp13;<span>
                    <div
                        style="display: inline-block;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;color: #FFFFFF;line-height: 22px;text-align: left;font-style: normal;">
                        > 项目概览</div>
                </span>
            </div>
            <el-button color="#6A5AE9" @click="exportList()"><img style="width: 22px;height: 22px;"
                    src="@/assets/export.png" alt="">导出</el-button>
        </div>

        <div>
            <div style="width: 97.5vw;background-color: #071229;margin: auto;margin-top:20px;padding-bottom: 24px;">
                <el-table :data="tableData" style="background-color: #071229;" :cell-style="cellStyle"
                    :header-cell-style="headerStyle">

                    <el-table-column prop="id" label="ID" />
                    <el-table-column prop="keyword" label="关键词">
                        <template #default="scope">
                            <div class="flex_row_center">{{ scope.row.keyword }}
                                <img v-if="scope.row.is_add" style="width: 16px;height: 16px;margin-left: 2px;"
                                    src="@/assets/tuo.png" alt="">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="调性情况">
                        <template #default="scope">
                            <div class="status">·非负：{{ scope.row.non_negative_count ? scope.row.non_negative_count : '0'
                                }}条
                            </div>
                            <div class="status red">·负面：{{ scope.row.negative_count ? scope.row.negative_count : '0' }}条
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="现状" min-width="460">
                        <template #default="scope">
                            <div class="flex_row_center">
                                <div class="flex_row_center" v-for="(e, i) in scope.row.plats">
                                    {{ e.plat ? getName(e.plat) : '' }}
                                    <!-- :class="(getName(e.plat) == '抖音' ? 'line1' : getName(e.plat) == '百度' ? 'line2' : getName(e.plat) == '微博' ? 'line3' : '')" -->
                                    <div class="line0" :style="{ background: e.color }">
                                    </div>
                                </div>
                                <!-- 小红书
                                <div class="line0"></div>
                                抖音
                                <div class="line0 line1"></div>
                                百度
                                <div class="line0 line2"></div>
                                微博
                                <div class="line0 line3"></div> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="employ" label="目标" min-width="180">
                        <template #default="scope">
                            <div style="margin: 6px 0;">
                                <div v-for="(e, i) in scope.row.plats">
                                    {{ e.plat ? getName(e.plat) : '' }}前{{ e.top_count ? e.top_count : '0' }}条非负率{{
                        e.top_rate ?
                            e.top_rate : '0' }}%;
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                        <template #default="scope">
                            <div style="display: flex;justify-content: center;cursor: pointer;">
                                <div style="color: #00E2FF;margin-right: 15px;" @click="turnDetail(scope.row)">查看详情
                                </div>
                                <div style="color: #00E2FF;" @click="handleEdit(scope.row)">调整修改</div>
                            </div>

                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-pagination background layout="prev, pager, next" :total="1000"
                    style="width: 352px;margin: auto;margin-top: 122px;" /> -->
            </div>
        </div>
        <div style="width: 688px; padding-left:15px;margin: auto; background-color: #fff;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;"
            v-if="beforeSearch">
            <div class="searchMain" v-for="exam in searchArr" :key="exam.id">
                <el-icon class="no-inherit" style="margin-right: 18px;position: relative;top: 3px;">
                    <Search />
                </el-icon> {{ exam.text }}
            </div>
        </div>


        <el-dialog v-model="centerDialogVisible" title="调整修改" width="742" center class="blackdialog">
            <el-form ref="ruleFormRef" :model="addform" label-width="auto" style="max-width: 600px;margin: auto;">
                <el-form-item label="关键词:" prop="keywords">
                    <el-input class="inputClass" v-model="addform.keyword" disabled placeholder="请输入初始关键词" />
                </el-form-item>
                <el-form-item label="目标:">
                    <div class="flex_row_left" style="margin-bottom: 10px;" v-for="(e, i) in addform.plats">
                        <el-select class="flex1" style="margin-right: 10px;" v-model="e.plat" placeholder="选择平台">
                            <el-option v-for="(e, i) in platList" :key="i" :label="e.title" :value="e.plat" />
                        </el-select>
                        <el-input class="flex1" style="margin-right: 10px;" v-model="e.top_count" placeholder="设置条数"
                            type="number" />
                        <el-input class="flex1" style="margin-right: 10px;" v-model="e.top_rate" placeholder="输入非负率"
                            type="number" />
                        <div class="flex_row_left homeadd" @click="addtargt" v-if="i == 0">
                            <img src="@/assets/homeadd.png" alt="">增加
                        </div>
                        <div class="flex_row_left homeadd homedel" @click="deltargt(i)" v-else>
                            <img src="@/assets/homedel.png" alt="">删除
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="save()">保存</el-button>
                    <el-button @click="centerDialogVisible = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import { https, baseURL } from '@/request.js';
import { ElMessage, ElMessageBox } from 'element-plus'
// const useRoute = useRoute()
const Router = useRouter()
const searchArr = ref([])
const beforeSearch = ref(true)

let breadWord = ref('')
console.log(searchArr)
let timer = null;
const tableData = ref([

])
function exportList() {
    let url = baseURL + '/index/Project/projectExport?id=' + JSON.parse(localStorage.getItem('currentProgram')).id;
    window.open(url, '_blank')
}
let centerDialogVisible = ref(false)
const platList = ref([])
function getName(e) {
    let i = platList.value.findIndex(item => item.plat == e)
    return platList.value[i].title
}
function getPlat() {
    https.post('/index/index/getPlat', {}).then(res => {
        if (res.code == 0) {
            platList.value = res.data
        }
    })
}
getPlat()
const addform = ref({
    project_id: 0,
    keyword: '',
    plats: [{
        plat: '',
        top_count: '',
        top_rate: ""
    }],
})
const addtargt = () => {
    addform.value.plats.push({
        plat: '',
        top_count: '',
        top_rate: ""
    })
}
const deltargt = (i) => {
    addform.value.plats.splice(i, 1)
}
function handleEdit(row) {
    let obj = JSON.parse(JSON.stringify(row))
    addform.value.project_id = JSON.parse(localStorage.getItem('currentProgram')).id;
    addform.value.keyword = obj.keyword;
    addform.value.plats = obj.plats.map(e => {
        let abc = {
            'plat': e.plat,
            'top_count': e.top_count,
            'top_rate': e.top_rate
        }
        return abc
    });
    centerDialogVisible.value = true
}
function save() {
    // addform.value.plats = JSON.stringify(addform.value.plats)
    https.post('/index/Project/keywordAdjust', {
        project_id: addform.value.project_id,
        keyword: addform.value.keyword,
        plats: JSON.stringify(addform.value.plats),
    }).then(res => {
        if (res.code == 0) {
            ElMessage.success({
                message: '修改成功!',
            })
            centerDialogVisible.value = false
            getDetail()
        }
    })
}
function getDetail() {
    https.post('/index/Project/projectDetail', {
        id: JSON.parse(localStorage.getItem('currentProgram')).id
    }).then(res => {
        if (res.code == 0) {
            tableData.value = res.data.keywords_list
        }
    })
}
getDetail()
const turnDetail = (v) => {
    Router.push({
        path: '/analysis/detail',
        query: {
            keywords: v.keyword
        }
    })
}
const backHome = () => {
    Router.go(-1)
}
const cellStyle = () => {
    return {
        color: "#fff",
        textAlign: "center",
        background: "#071229",
        fontWeight: 700,
        height: '46px',
        lineHeight: '46px',
        padding: '0px',
        border: 'none',
        borderBottom: '1px solid rgba(15,178,245,0.11)',
    };

}
const headerStyle = () => {
    return {
        color: "#fff",
        textAlign: "center",
        background: "rgba(106, 90, 233, 0.12)",
        fontWeight: 700,
        height: '45px',
        lineHeight: '45px',
        border: 'none'
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.head {
    width: 97.5vw;
    margin: auto;
    text-align: left;
    margin-top: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.line0 {
    width: 28px;
    height: 3px;
    background: #FF1515;
    border-radius: 3px;
    margin: 0 10px 0 4px
}

.line1 {
    background: #EAB31A;
}

.line2 {
    background: #1A60DA;
}

.line3 {
    background: #1ADA33;
    margin: 0 0px 0 4px
}

.homeadd {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #0777FC;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
}

.homeadd img {
    width: 14px;
    height: 14px;
    margin-right: 2px;
}

.homedel {
    color: #FF4453;
}

.status {
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #00DFFF;
    line-height: 22px;
    text-align: center;
    font-style: normal;
}

.red {
    color: #FF355E;
}

.breadcrumbs {
    color: white;
}

.searchMain {
    height: 40px;
    line-height: 40px
}

.overview {
    width: 100vw;
    height: 100vh;
    background-image: url(@/assets/bgi.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow-x: hidden;
}
</style>