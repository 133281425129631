<template>
  <div>
    <div class="logo">
    </div>
    <div class="hello">
      <div class="form">
        <div class="titleLogo"></div>
        <div
          style="margin: 24px auto;margin-bottom: 0px;font-family: PingFangSCSemibold, PingFangSCSemibold;font-weight: normal;font-size: 30px;color: #333333;line-height: 42px;font-style: normal;">
          欢迎登陆</div>
        <el-input v-model="username" placeholder="请输入账号" class="input-with-select">
          <template #prefix>
            <el-icon color="#409EFC" size="22px" class="no-inherit">
              <User />
            </el-icon>
          </template>
        </el-input>
        <el-input v-model="password" placeholder="请输入密码" type="password" class="input-with-select">
          <template #prefix>
            <el-icon color="#409EFC" size="22px" class="no-inherit">
              <Lock />
            </el-icon>
          </template>
        </el-input>
        <div class="flex_row_left">
          <el-input v-model="codenum" placeholder="请输入验证码" class="input-with-select flex1" @keyup.enter="loginIn">
            <template #prefix>
              <img src="@/assets/Shield.png" style="width: 22px;height: 22px;" alt="">
            </template>
          </el-input>
          <!-- <div style="margin-top: 24px;" id="v_container" /> -->
          <img style="width: 121px;height: 42px;margin-left: 24px;margin-top: 24px;cursor: pointer;" :src="codeimg" alt=""
            @click="getImg()">
        </div>


        <div style="margin-top: 4.4vh;width: 100%;">
          <el-button class="btn" style="width: 100%;" @click="loginIn()" type="primary">登录</el-button>
        </div>
        <div style="margin-top: 30px;" class="flex_row_center">
          <div style="width: 60px;height: 0px;border: 1px solid #BEC4CD;margin: 0 14px;"></div>
          <div class="bottomText">SEO智能营销系统</div>
          <div style="width: 60px;height: 0px;border: 1px solid #BEC4CD;margin: 0 14px;"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { GVerify } from '@/js/code.js'
import { ElMessage } from 'element-plus'
import { https, baseURL } from '@/request.js';
const userRoute = useRoute()
const userRouter = useRouter()
var verifyCode;
const codeimg = ref('')
onMounted(() => {
  // verifyCode = new GVerify('v_container')
  getImg()
})
// function getA() {
//   https.post('/index/User/getAuthGroup').then(res => {
//     console.error(res)
//   })
// }
// setTimeout(() => {
//   getA()
// }, 2000);
function getImg() {
  let imgurl = baseURL + '/index.php?s=/captcha&t=' + new Date().getTime()
  codeimg.value = imgurl;
}
console.log(userRouter, userRoute);
let username = ref('')
let password = ref('')
let codenum = ref()
const loginIn = () => {
  if (codenum.value) {
    https.post('index/login/index', {
      username: username.value,
      password: password.value,
      verify: codenum.value
    }).then(res => {
      if (res.code == 0) {
        ElMessage.success({
          message: '登陆成功!',
        })
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('username', res.data.username)
        userRouter.push({
          path: '/second'
        })
      } else {
        getImg()
      }
    })
    // const verifyFlag = verifyCode.validate(codenum.value)
    // if (verifyFlag) {

    // } else {
    //   ElMessage.error('请输入正确的图形验证码!')
    //   verifyCode.refresh()
    // }
  } else {
    ElMessage.error('请输入图形验证码!')
  }

}
</script>
<style scoped>
.bottomText {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #BEC4CD;
  line-height: 17px;
  letter-spacing: 9px;
  text-align: center;
  font-style: normal;
  min-width: 178px;
}

.input-with-select {
  margin-top: 24px;
  width: 100%;
  height: 42px;
}

.hello {
  background-image: url(@/assets/backgroundImage.png);
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  background-image: url(@/assets/logo.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12vw;
  height: 4.6vh;
  position: absolute;
  top: 24px;
  left: 24px;
}

.form {
  width: 538px;
  height: 556px;
  padding: 56px 80px 37px 80px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
}

.titleLogo {
  background-image: url(@/assets/logo2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12vw;
  height: 4.6vh;
  margin: auto;
}

#v_container {
  width: 121px;
  height: 42px;
  margin-left: 24px;
}

::v-deep .el-input__inner {
  color: #333 !important;
}

.btn {
  width: 378px;
  height: 54px;
  background: #0777FC;
  border-radius: 2px;
  font-family: PingFangSC, PingFang SC;
  font-weight: normal;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  letter-spacing: 3px;
}
</style>