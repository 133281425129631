<template>
  <div class="skipSearch">
    <div style="display: flex;justify-content: center;margin-top: 144px;" v-if="beforeSearch">
      <el-input v-model="searchKey" style="width: 703px;margin: auto;" placeholder="请输入搜索关键词" class="input-with-select"
        @input="inputSearchKey" v-on:keyup.enter="searchSomething()">
        <template #prefix>
          <el-icon color="#FFFFFF" class="no-inherit">
            <Search />
          </el-icon>
        </template>
        <template #append>
          <el-button @click="searchSomething" style="background-color:#0777FC ;color: white;">搜索</el-button>
        </template>
      </el-input>
    </div>
    <div style="width: 97.5vw;margin: auto;margin-top: 84px;" v-else>
      <div class="head">
        <div class="breadcrumbs">
          <span>
            <div @click="turnSearch"
              style="display: inline-block;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;color: rgba(255,255,255,0.4);line-height: 22px;text-align: left;font-style: normal;cursor: pointer;">
              关键词搜索</div>
          </span> &emsp13;<div style="display: inline-block;" v-if="breadWord.split('').length > 0">></div>
          &emsp13;<span>
            <div
              style="display: inline-block;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;color: #FFFFFF;line-height: 22px;text-align: left;font-style: normal;">
              {{ breadWord }}</div>
          </span>
        </div>
        <div class="buttonArr">
          <el-button type="primary" @click="handleDelete(2)"><img style="width: 22px;height: 22px;"
              src="@/assets/export.png" alt=""> 导出</el-button>
          <el-button color="#6A5AE9" @click="handleDelete(1)">自定义列表</el-button>
        </div>
      </div>
      <div style="width: 97.5vw;background-color: #071229;margin: auto;margin-top:20px;padding-bottom: 24px;">
        <el-table :data="tableData" style="background-color: #071229;" :cell-style="cellStyle"
          :header-cell-style="headerStyle" @sort-change="sortChange">
          <template v-for="(e, i) in colList">


            <el-table-column v-if="e.prop == 'level'" :prop="e.prop" :label="e.label" :row-key="i" min-width="150">
              <template #default="scope">
                <el-rate v-model="scope.row.level" disabled />
              </template>
            </el-table-column>
            <el-table-column v-else-if="e.prop == 'wjkeyword'" :prop="e.prop" :label="e.label" :row-key="i"
              min-width="150">
              <template #default="scope">
                <div class="flex_row_center">{{ scope.row.wjkeyword }}
                  <img v-if="scope.row.is_add" style="width: 16px;height: 16px;margin-left: 2px;" src="@/assets/tuo.png"
                    alt="">
                </div>
              </template>
            </el-table-column>
            <el-table-column v-else :prop="e.prop" :label="e.label" :row-key="i"
              :sortable="e.prop == 'wjkeyword' ? false : 'custom'" min-width="150" />
          </template>

          <!-- <el-table-column prop="id" label="ID" max-width="180" />
          <el-table-column prop="wjkeyword" label="关键词" max-width="180" />
          <el-table-column prop="bidword_company_count" label="竞价公司数量" sortable="custom" max-width="180" />
          <el-table-column prop="long_keyword_count" label="长尾词数量" sortable="custom" max-width="180" />
          <el-table-column prop="zhishu" label="流量指数" sortable="custom" max-width="180" />
          <el-table-column prop="mobile_index" label="移动指数" sortable="custom" max-width="180" />
          <el-table-column prop="haosou_index" label="360指数" sortable="custom" max-width="180" />
          <el-table-column prop="bidword_pcpv" label="PC日检索量" sortable="custom" max-width="180" />
          <el-table-column prop="bidword_wisepv" label="移动日检索量" sortable="custom" max-width="180" />
          <el-table-column prop="toutiao_index" label="头条指数" sortable="custom" max-width="180" />
          <el-table-column prop="douyin_index" label="抖音指数" sortable="custom" max-width="180" />
          <el-table-column prop="kuaishou_index" label="快手指数" sortable="custom" max-width="180" />
          <el-table-column prop="google_index" label="google指数" sortable="custom" max-width="180" />
          <el-table-column prop="weibo_index" label="微博指数" sortable="custom" max-width="180" /> -->
          <el-table-column label="操作" fixed="right" width="160">
            <template #default="scope">
              <div style="display: flex;justify-content: flex-start;cursor: pointer;">
                <div style="color: #00E2FF;" @click="handleDelete(3, scope.$index, scope.row)">调整</div>
                <div style="width: 1px;height: 15px;background: #D8D8D8;opacity: 0.23;margin: 5px 10px;"></div>
                <div v-if="!scope.row.is_add" style="color: #FF7900;" @click="addExp(scope.row)">添加至拓展词</div>
                <el-popconfirm v-else width="220" confirm-button-text="确认删除" cancel-button-text="取消"
                  confirm-button-type="danger" icon-color="red" title="确定删除拓展词吗?" @confirm="delExp(scope.row)">
                  <template #reference>
                    <div style="color: #6B6B6B;">删除拓展词</div>
                  </template>
                </el-popconfirm>


              </div>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="count" :page-size="params.pagesize"
          :current-page="params.page" :default-current-page="1" @current-change="currentChange"
          style="margin-top: 122px;" class="flex_row_center" />
      </div>
    </div>
    <!-- <div
      style="width: 688px; padding-left:15px;margin: auto; background-color: #fff;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;"
      v-if="beforeSearch">
      <div class="searchMain" v-for="exam in searchArr" :key="exam.id">
        <el-icon class="no-inherit" style="margin-right: 18px;position: relative;top: 3px;">
          <Search />
        </el-icon> {{ exam.text }}
      </div>
    </div> -->
    <el-dialog v-model="dialogShow" :title="titleReturn()" :width="dialogIndex === 1 ? '746' : '366'" align-center
      class="blackdialog">
      <div v-if="dialogIndex === 1">
        <div class="" style="padding: 32px 80px;">
          <el-checkbox style="width: 100%;" v-model="checkAll" @change="handleCheckAllChange"
            :indeterminate="isIndeterminate">全选</el-checkbox>
          <el-checkbox v-for="(item, index) in headList" :key="index" @change="handleCheckedBoxChange"
            v-model="item.show" style="width: 18%;margin-top: 30px;">
            {{ item.label }}
          </el-checkbox>
        </div>
      </div>
      <div style="display:flex;align-items: center;" v-else-if="dialogIndex === 2">
        <span style="margin-right: 6px;">设置导出数量</span>
        <el-input v-model="keywordsnum" style="width: 240px;" placeholder="请输入" />
      </div>
      <div style="margin-left: 86px;display: flex;align-items: center;" v-else>
        <span>推荐度</span>
        <el-rate v-model="level" />
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="save()">保存</el-button>
          <el-button @click="dialogShow = false">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
const searchArr = ref([])
const searchKey = ref('')
const beforeSearch = ref(true)
let breadWord = ref('')
import { https, baseURL } from '@/request.js';
console.log(searchArr)
let timer = null;
const dialogShow = ref(false)
const level = ref(null)
const levelid = ref(0)
const dialogIndex = ref(0)
// const titleDialog = ref('')
const tableData = ref([

])
const keywordsnum = ref()

const checkAll = ref(true)
const headList = ref([
  { label: 'ID', prop: 'id', show: true },
  { label: '关键词', prop: 'wjkeyword', show: true },
  { label: '竞价公司数量', prop: 'bidword_company_count', show: true },
  { label: '长尾词数量', prop: 'long_keyword_count', show: true },
  { label: '流量指数', prop: 'zhishu', show: true },
  { label: '移动指数', prop: 'mobile_index', show: true },
  { label: '360指数', prop: 'haosou_index', show: true },
  { label: 'PC日检索量', prop: 'bidword_pcpv', show: true },
  { label: '移动日检索量', prop: 'bidword_wisepv', show: true },
  { label: '头条指数', prop: 'toutiao_index', show: true },
  { label: '抖音指数', prop: 'douyin_index', show: true },
  { label: '快手指数', prop: 'kuaishou_index', show: true },
  { label: 'google指数', prop: 'google_index', show: true },
  { label: '微博指数', prop: 'weibo_index', show: true },
  { label: '推荐度', prop: 'level', show: true },
])
const isIndeterminate = ref(false)
function handleCheckAllChange() {
  headList.value.forEach(item => {
    item.show = checkAll.value;
  });
  if (checkAll.value) {
    isIndeterminate.value = false
  }
  colList.value = headList.value.filter(item => item.show == true);
}
const colList = ref([])
colList.value = headList.value;
function handleCheckedBoxChange() {
  let allTrue = headList.value.every(item => item.show === true);
  colList.value = headList.value.filter(item => item.show == true);
  if (allTrue) {
    checkAll.value = true
    isIndeterminate.value = false
  } else {
    checkAll.value = false
    isIndeterminate.value = true
  }
}


function sortChange(e) {
  params.value.order = e.prop;
  if (e.order == 'ascending') {
    params.value.sort = 'asc'
  } else if (e.order == 'descending') {
    params.value.sort = 'desc'
  }
  getList()
}
function save(i) {
  if (dialogIndex.value == 3) {
    if (level.value) {
      https.post('/index/Keyword/keywordZhishuLevelAdjust', {
        id: levelid.value,
        level: level.value
      }).then(res => {
        if (res.code == 0) {
          ElMessage.success({
            message: '调整成功!',
          })
          getList()
          dialogShow.value = false;
        }
      })
    }
  } else if (dialogIndex.value == 2) {
    if (keywordsnum.value) {
      let url = baseURL + '/index/Keyword/keywordZhishuExport?keyword=' + breadWord.value + '&num=' + keywordsnum.value;
      dialogShow.value = false;
      window.open(url, '_blank')
    } else {
      ElMessage.error('请输入导出数量')
    }
  } else {
    dialogShow.value = false;
  }
}
const titleReturn = () => {
  if (dialogIndex.value === 1) {
    return '选择表格标题'
  } else if (dialogIndex.value === 2) {
    return '导出'
  } else {
    return '调整'
  }
}
const handleDelete = (v, i, e) => {
  dialogIndex.value = v
  if (v == 3) {
    levelid.value = e.id;
    level.value = e.level;
  }
  dialogShow.value = true
}
const turnSearch = () => {
  beforeSearch.value = true
  breadWord.value = ''
}
const cellStyle = () => {
  return {
    color: "#fff",
    textAlign: "center",
    background: "#071229",
    fontWeight: 700,
    height: '46px',
    lineHeight: '46px',
    padding: '0px',
    border: 'none',
    borderBottom: '1px solid rgba(15,178,245,0.11)',
  };

}
const headerStyle = () => {
  return {
    color: "#fff",
    textAlign: "center",
    background: "rgba(106, 90, 233, 0.12)",
    fontWeight: 700,
    height: '45px',
    lineHeight: '45px',
    border: 'none'
  }
}
const params = ref({
  keywords: "",
  page: 1,
  pagesize: 10,
  order: 'id',
  sort: 'asc',
  project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
})
const count = ref(0)
const searchSomething = () => {
  breadWord.value = searchKey.value
  params.value.keywords = searchKey.value
  searchKey.value = ''
  beforeSearch.value = false
  getList()
}
function getList() {
  https.post('/index/Keyword/keywordZhishu', params.value).then(res => {
    if (res.code == 0) {
      tableData.value = res.data.list
      count.value = res.data.page_config.count
    }
  })
}
function currentChange(val) {
  params.value.page = val;
  getList()
}
const inputSearchKey = () => {
  if (searchKey.value.split('').length === 0) {
    searchArr.value = []
  } else {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      console.log('????')
      console.log(111)
      for (let i = 0; i < 10; i++) {
        searchArr.value.push({
          id: i,
          text: '猜猜我搜的啥'
        })

      }
    }, 2000);
  }

}

function addExp(e) {
  https.post('/index/Project/addExtendKeyword', {
    project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
    keyword: e.wjkeyword
  }).then(res => {
    if (res.code == 0) {
      getList()
    }
  })
}
function delExp(e) {
  https.post('/index/Project/delExtendKeyword', {
    project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
    keyword: e.wjkeyword
  }).then(res => {
    if (res.code == 0) {
      getList()
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.head {
  display: flex;
  justify-content: space-between;
}

.breadcrumbs {
  color: white;
}

.searchMain {
  height: 40px;
  line-height: 40px
}

.skipSearch {
  width: 100vw;
  height: 100vh;
  background-image: url(@/assets/bgi.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

::v-deep .el-table__header-wrapper tr th.el-table-fixed-column--right,
::v-deep .el-table__header-wrapper tr th.el-table-fixed-column--left {
  background: #131A41 !important;
}
</style>